<template>
  <div class="content"  v-loading="loadingStatus.getTable">
    <div>
      <el-form ref="staffRef" :inline="true" size="medium" :model="dataForm" label-width="120px">
        <el-form-item label="游戏：">
          <el-select v-model="dataForm.gameId">
            <el-option v-for="(item) in gamesList" :key="item.id"
                       :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="充值时间：">
          <el-date-picker
              v-model="time"
              clearable
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="getTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-button type="primary" @click="getTable" size="medium">查询</el-button>
        <el-button style="margin-bottom: 10px" plain @click="getReset" size="medium">重置</el-button>
      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="gameId" label="游戏ID" align="center"></el-table-column>
        <el-table-column prop="gamesName" label="游戏名称" align="center"></el-table-column>
<!--        <el-table-column prop="cleaningCycle" label="结算周期" align="center"></el-table-column>-->
        <el-table-column prop="account" label="充值金额" align="center"></el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import loadingStatusMixin from "@/mixins/loadingStatus";
import {commonInfo, dataStatisticsRechargeStatisticsList} from "../../service/moudules/promotionLinkApi";
export default {
  name: "recharge",
  mixins: [loadingStatusMixin],

  data (){
    return {
      dataForm: {
        gameId: '', //游戏ID
        startDate: '',
        endDate: '',
      },
      time: [],
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      groupList: [], //小组
      empList: [], //员工信息
      gamesList: [], //游戏
    }
  },
  mounted() {
    /*let beforeDay = moment(new Date()).subtract(1,'day').format('yyyy-MM-DD');
    let whenDay = moment(new Date()).format('yyyy-MM-DD');
    this.dataForm.startDate = beforeDay;
    this.dataForm.endDate = whenDay;
    this.time = [beforeDay,whenDay];*/
    this.getTable();
    this.getCommonInfo();
  },
  methods:{
    async getCommonInfo() {
      let res = await commonInfo();
      if (res.code == 200) {
        this.groupList = res.data.groupList;
        this.empList = res.data.empList;
        this.gamesList = res.data.gamesList;
      }
    },
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataStatisticsRechargeStatisticsList(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          this.page.pageNo = res.data.pages;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getTime(val) {
      if (val && val.length > 0) {
        let [x , y] = val;
        this.dataForm.startDate = x+' 00:00:00';
        this.dataForm.endDate = y+' 23:59:59';
      }else {
        this.dataForm.startDate = '';
        this.dataForm.endDate = '';
      }
    },
    getReset() {
      /*let beforeDay = moment(new Date()).subtract(1,'day').format('yyyy-MM-DD');
      let whenDay = moment(new Date()).format('yyyy-MM-DD');
      this.dataForm = {
        gameId: '',
        startDate: beforeDay,
        endDate: whenDay
      };
      this.time = [beforeDay,whenDay];*/
      this.dataForm = {
        gameId: '',
        startDate: '',
        endDate: ''
      };
      this.time = [];
      this.getTable();
    }
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header th.el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>